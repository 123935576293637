import React from 'react';
import './BuildFor.css'; // Assuming you have a CSS file for styling

const BuildFor = () => {
    return (
        <div className="build-for-container">
            <label className='betaMain'>  كورا باي أعمال  ●</label>
            <h1 className="build-title">انطلق إلى آفاق أبعد وبسرعة أكبر</h1>
            <p className="build-subtitle">تقرب المسافة بين ثقة العملاء وكفاءة التجار، مما يوفر عملية دفع مرنة وآمنة للطرفين</p>

            <div className="Featuers-build">

                <div className="Featuers-buildCard">
                    <h2>حسّن تجربة إتمام الشراء</h2>
                    <p>شجع عملائك على بديل الدفع عند الاستلام بمزايا افضل</p>
                </div>

                <div className="Featuers-buildCard">
                    <h2>أطلق العنان لمبيعاتك</h2>
                    <p>حسن تدفقاتك المالية دون تأخير في استلام المدفوعات أو تأثير على العمليات</p>
                </div>

                <div className="Featuers-buildCard">
                    <h2>الحد من الطلبات وهمية</h2>
                    <p>طلبات يتم إنشاؤها بغرض عدم الاستلام أو التأثير السلبي على معدلات الأداء وإفساد تجربة المستخدم</p>
                </div>

                <div className="Featuers-buildCard">
                    <h2>قلل معدلات الإرجاع</h2>
                    <p>نسبة الطلبات التي تتم إرجاعها بعد إتمام عملية الدفع أو التسليم</p>
                </div>

            </div>
        </div>
    );
};

export default BuildFor;
