import React from 'react';
import { useNavigate } from 'react-router-dom';
import './sectionTwo.css';

function SectionTwo() {

    const navigate = useNavigate();

    return (
        <div className="section-two-container">

            <div className="features-container">

                <div className="linksfeature">
                    <div className="text-container" >
                        <h2 className="textLink">كورا باي</h2>
                        <p>حلول المدفوعات عند الاستلام بطريقة مبتكرة</p>
                    </div>
                    <div className="icon-container">
                        <img className="linked" src="/coraW.png" alt="Create Icon" />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SectionTwo;
