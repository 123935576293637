import React from 'react';
import './Featuers.css';

function Features() {
    return (
        <div className="Featuers-section">

            <label className='betaMain'>  مزايا  ●</label>
            <h1 className="main-title">خيارات مرنة تناسب احتياجاتك ومرنة لدفعاتك</h1>

            <div className="Featuers-container">

                <div className="Featuers-card">
                    <img src="wallet.png" alt="Feature Image" className="feature-image" />
                    <h2>ادفع فاتورتك كاملة مرة وحدة</h2>
                    <p>ادفع فاتورتك بسهولة كاملة مرة واحدة واستمتع براحة البال</p>
                </div>

                <div className="Featuers-card">
                    <img src="checkout.png" alt="Feature Image" className="feature-image" />
                    <h2>تسريع عملية الخروج</h2>
                    <p>لا حاجة لإعادة إدخال البيانات، معلوماتك محفوظة لتجربة دفع أسرع وأسهل</p>
                </div>

                <div className="Featuers-card">
                    <img src="shcd.png" alt="Feature Image" className="feature-image" />
                    <h2>جدولة الدفعات</h2>
                    <p>خطط لدفع المبالغ المتبقية بمرونة تصل إلى 30 يومًا</p>
                </div>

                <div className="Featuers-card">
                    <img src="2pay.png" alt="Feature Image" className="feature-image" />
                    <h2>على دفعتين</h2>
                    <p>ابدأ خدمة الدفع عند الاستلام إلكترونيًا بكل سهولة وراحة. يمكنك دفع 20% مقدمًا وإكمال باقي المبلغ عند استلام الطلب، لتجربة دفع مرنة وآمنة</p>
                </div>

            </div>
        </div>
    );
};

export default Features;
