import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Tryout.css';


function Tryout() {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        window.location.href = 'https://dashboard.cora.sa';
    };


    return (
        <div className="TryoutContainer">
            <div className="tryout-content">
                <p>فعل حسابك خلال لحظات، وابدأ بتجربة سريعة ومباشرة!</p>
                <div className="tryout-buttons">
                    <button className="get-started-button" onClick={handleGetStarted}>ابدأ الآن</button>
                </div>
            </div>
        </div >
    );
}

export default Tryout;