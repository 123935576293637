import React, { useState } from 'react';
import './Faq.css'; // Import the CSS file for styling
import { Helmet } from 'react-helmet';

const faqData = [
    {
        question: "كورا Cora",
        answer: "منصه تقدم مجموعة من الحلول للدفع واستقبال المدفوعات عبر الانترنت"
    },
    {
        question: "كيف أبدأ باستقبال المدفوعات؟",
        answer: "يمكنك البدء بالتسجيل في الموقع وتفعيل البيئة التجريبية في لحظات، ثم إعداد حساب التاجر لتفعيل الخدمة في البيئة الفعلية"
    },
    {
        question: "كم يستغرق مدة تفعيل الحساب في البيئة الفعلية؟",
        answer: "يتم التحقق من صحة البيانات واكتمالها في غضون يومي عمل كحد أقصى، فور اكتمال جميع المعلومات المطلوبة يتم تفعيل الحساب"
    },
    {
        question: "كورا باي؟",
        answer: "خدمة الدفع عند الاستلام بمرونة إضافية تتيح للعملاء الدفع على دفعتين؛ دفعة عند الطلب ودفعة عند الاستلام، مما يعزز الثقة ويسهل إتمام المعاملات"
    },
    {
        question: "هل يمكن أن تعمل كورا كخدمة مكملة لأنظمة دفع أخرى؟",
        answer: "نعم، كورا يمكنها التكامل مع أنظمة دفع أخرى، مما يجعلها حلاً مرنًا يعزز خدمات الدفع الحالية ويوفر المزيد من الخيارات للعملاء، الدفع عند الاستلام، وخيارات الدفع المتعددة، ما يرفع من كفاءة العمليات المالية ويساهم في تحسين تجربة المستخدم"
    },
    {
        question: "هل تدعم كورا الدفع التلقائي؟",
        answer: "نعم، توفر كورا إمكانية الدفع التلقائي وإدارة المدفوعات الدورية بشكل سهل ومرن"
    },
    {
        question: "هل يوجد رسوم تأسيس؟",
        answer: "لا يوجد أي رسوم تأسيسية أو شهرية وتتمثل الرسوم كنسبة ورسوم تشغيلية ثابتة على العمليات غير شاملة لضريبة القيمة المضافة، ويتم احتسابها بحسب شبكة الدفع"
    },
    {
        question: "متى يتم تسوية المبلغ إلى وما هو الحد الأدنى؟",
        answer: "يتم تسوية ومعالجة المدفوعات اسبوعياً، الحد الأدنى لتحصيل المبالغ هو 100 ريال"
    },
    {
        question: "هل كورا شركة معتمدة؟",
        answer: "شركة كورا لتقنية المعلومات مسجلة بشكل رسمي في المملكة العربية السعودية , تعمل كـ حلول دفع الكترونية، وهي واحدة من الشركات الريادية المحتضنة لدى البرنامج الوطني لتنمية تقنية المعلومات"
    }
];



function FAQ() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div>

            <Helmet>
                <title>كورا - اسئلة مكرره </title>
            </Helmet>

            <h1 className='faq-title'>اسئلة مكرره</h1>
            <div className="faq-container">
                {faqData.map((item, index) => (
                    <div key={index} className="faq-item">

                        <div className="faq-question-container" onClick={() => toggleAnswer(index)}>

                            <span className="faq-icon">
                                {activeIndex === index ? '✕' : '＋'}
                            </span>

                            <h2 className="faq-question">
                                {item.question}
                            </h2>

                        </div>

                        {activeIndex === index && <p className="faq-answer">{item.answer}</p>}

                    </div>
                ))}
            </div>
        </div>

    );
}

export default FAQ;
